import React, {Fragment} from 'react';
import { Row, Col, Container, Card, Form, Alert, Collapse } from 'react-bootstrap';
import 'antd/dist/antd.css';
import "react-datepicker/dist/react-datepicker.css";

  
const ConsumerSiteInfoContent = () => {

    return (
        <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>SunPower Rebate Consumer Site Information</h3>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <h6>
                                SunPower Rebate Consumer Site
                            </h6>
                            <a href="https://sunpowerrebate.com/" target="_blank" className="blue-link">SunPowerRebate.com</a>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <h6>
                                SunPower Rebate Customer Service
                            </h6>
                            <a href="https://sunpowerrebate.com/contactus" target="_blank" className="blue-link">SunPowerRebate.com/contactus</a>
                            <p className="pt-2 mb-0">
                                800 - 409 - 8960
                            </p>
                            <p className="mb-0">
                                Mon - Fri | 8:00 AM - 4:00PM PST
                            </p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <h6>
                                Active Alliance
                            </h6>
                            <a href="mailto:allianceprogram@sunpower.com" className="blue-link">AllianceProgram@sunpower.com</a>
                        </Col>
                    </Row>
                </Container>
    );
}
export default ConsumerSiteInfoContent;
