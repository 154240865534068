import React, {Fragment} from 'react';
import SideCard from './SideCard';
import Topper from './Topper';
import DashboardContent from './DashboardContent';
import { Footer } from '../../footer/Footer';


const Dashboard = () => {
    return (
        <Fragment>
            <Topper />
            <DashboardContent />
            <Footer></Footer>
        </Fragment>
    );
}
export default Dashboard;