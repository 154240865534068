import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

class OpenClose extends Component {
    constructor(props){
        super(props);
        this.state = {
          arrowUp1 : false,
        }
        this.toggleArrow = this.toggleArrow.bind(this);
        
    }

    toggleArrow() {
        this.setState({arrowUp: !this.state.arrowUp});
    }
  
    render() {
        const arrowDown = faAngleDown
        const arrowUp = faAngleUp
        const questionText = this.props.question

      return (
          <Fragment>
              <FontAwesomeIcon icon={ this.state.arrowUp ? arrowUp : arrowDown } id={this.props.id} onClick={ this.toggleArrow } />  <span>{ questionText }</span>
          </Fragment>
        
      );
    }
}

export default OpenClose