import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {Container, Col} from 'react-bootstrap';
import { Collapse, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { userActions } from '../../_actions';
import Topper from "./Topper";
import '../HomePage.css';
import './Faqs.css';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'*/
import { Footer } from '../../footer/Footer';
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';
import OpenClose  from './OpenClose';
import Row from 'reactstrap/lib/Row';

class Faqs extends Component {
    constructor(props){
        super(props);
        this.state = {
          arrowUp1 : false,
        }
        this.toggleArrow = this.toggleArrow.bind(this);
        
      }

    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }

    toggleArrow() {
        this.setState({arrowUp: !this.state.arrowUp});
    }

    render() {
        const { user, users } = this.props;
        const header = <h4>Project Information</h4>;
        /*const arrowDown = faAngleDown
        const arrowUp = faAngleUp*/
        

        const { Panel } = Collapse;


        return (

            <Fragment>
                <Topper />
                <Container className="text-start py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5 pb-md-4">
                            <h3>Frequently Asked Questions</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Collapse
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
                                className="site-collapse-custom-collapse bg-white">

                                <Panel header="How do you Approve a rebate that was Declined on accident?" key="1" className="site-collapse-custom-panel">
                                <p>On the <a href="/contactus" className="blue-link">Contact Us</a> page, enter the Rebate ID and/or first name, last name along with home address of the homeowner and a representative will assist you further.</p>
                                </Panel>
                                <Panel header="How do you Decline a rebate that was Approved on accident?" key="2" className="site-collapse-custom-panel">
                                <p>We can decline the rebate ONLY if the SunPower rebate was not issued to the homeowner.</p>
                                </Panel>
                                <Panel header="What if the Customer entered the incorrect home address?" key="3" className="site-collapse-custom-panel">
                                <p>You can decline the rebate, then have your customer resubmit a new rebate with the correct data; ONLY if the rebate has not been approved.</p>
                                </Panel>
                                <Panel header="How long will it take for my customer to receive their SunPower rebate?" key="4" className="site-collapse-custom-panel">
                                <p>It takes approximately 8-10 weeks for the SunPower rebate to arrive from the date of approval.</p>
                                </Panel>
                                <Panel header="What is the procedure if a customer has not received their rebate in the 7 week period?" key="5" className="site-collapse-custom-panel">
                                <p>Visit the <a href="/contactus" className="blue-link"> Contact Us</a> page and enter the rebate ID and/ or first name, last name, home address and email of the customer you are inquiring about and a representative will ensure all data is entered correctly, as well as, sharing the current status of your customer’s rebate.</p>
                                </Panel>
                                <Panel header="What if a customer does not have their rebate code?" key="6" className="site-collapse-custom-panel">
                                <p>Select the "Active Rebate Codes" tab on the top of the SunPower Dealer Rebate Portal homepage. From there, select the Active Rebate Codes option. A PDF will automatically download with all the current Active Rebate Codes.</p>
                                </Panel>
                                <Panel header="How long does a customer have until their card expires?" key="7" className="site-collapse-custom-panel">
                                <p>Customers' cards will expire after midnight EST on the last day of the month of the valid thru date printed on the front of the card. Six months from the date of issuance. After card expiration, if you have not activated we can reissue a new card. Cards that have been activated may not be reissued.</p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </Fragment>

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHomePage = connect(mapState, actionCreators)(Faqs);
export { connectedHomePage as Faqs };