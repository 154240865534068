
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Form, Input, Button } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './AddUsersForm.css';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class HorizontalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    let submitBut = false;
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    //console.log(this.props.form.getFieldsError());
    this.submitBut = true;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.submitBut = false;
        const userVar = JSON.parse(localStorage.getItem('user'));
        console.log('Received values of form: ', values);
        axios.post(process.env.REACT_APP_BACKEND_URL+"/adddealeruser.php",{data: values, userVar: userVar})
            .then(response => {
                console.log(response);
                window.location.href = '/listusers';
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
      }
    });
  };

  validateEmailUniqueness = (rule, value, callback) => {
    const { form } = this.props;
    if (value === undefined && this.submitBut) {
      callback(`Email is required`);
    } else if (!value.length) {
      callback(`Email is required`);
    }
    if (value) {
        axios.post(process.env.REACT_APP_BACKEND_URL+"/checkifuniqueemail.php",{email: value})
            .then(response => {
                console.log(response);
                if (response.data.emailError) {
                    callback('This email already exists in the system and you cannot add this email again.');
                } else {
                    callback();
                }
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
      
    } else {
      callback();
    }
  };

  validateRequired = (rule, value, callback) => {
    const { form } = this.props;
    const mapping = { 'fname': 'First Name', 'lname': 'Last Name'};
    
    if (value === undefined && this.submitBut) {
      callback(`${mapping[rule.field]} is required`);
    } else if (!value.length) {
      callback(`${mapping[rule.field]} is required`);
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    // Only show error after a field is touched.
    const formItemLayout = {
        labelcol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrappercol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const tailFormItemLayout = {
        wrappercol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
    return (
        <Fragment>
          <Container className="py-5 vh-fill">
            <Row className="py-md-5">
              <Col className="pt-5">
                <h3>Add User</h3>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <label class="form-label fs-6">Email:</label>
                  <Form.Item validateStatus="">
                    {getFieldDecorator('email', { 
                      rules: [
                        {
                          type: 'email',
                          required: true,
                          message: 'Please provide a valid email address.'
                        },
                        
                        {
                          validator: this.validateEmailUniqueness,
                        },
                      ],  
                    })(<Input className="form-control" name="email" />)}
                  </Form.Item>
                  <label class="form-label fs-6">First Name:</label>
                  <Form.Item
                    validateStatus=""
                  >
                    {getFieldDecorator('fname', {
                      validationTrigger: "onSubmit",
                      rules: [
                        {
                          required: true,
                          validator: this.validateRequired
                        }
                      ],
                    })(<Input name="fname" />)}
                  </Form.Item>
                  <label class="form-label fs-6">Last Name:</label>
                  <Form.Item
                    validateStatus=""
                  >
                    {getFieldDecorator('lname', {
                      validationTrigger: "onSubmit",
                      rules: [
                        {
                          required: true,
                          validator: this.validateRequired
                        }
                      ],
                    })(<Input name="lname" />)}
                  </Form.Item>
                  <Form.Item>
                    <Button className="btn-orange" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
        </Container>
      </Fragment>
    );
  }
}

const AddUsersForm = Form.create({ name: 'horizontal_login' })(HorizontalLoginForm);
export default AddUsersForm