import React, { Fragment } from 'react';
import { Navbar, Nav, NavDropdown, Container, Dropdown, Button } from 'react-bootstrap';
import './Topper.css';
import { UserOutlined, FilePdfOutlined } from '@ant-design/icons';

export default class Topper extends React.Component {
  constructor(props) {
    super(props);
    const userVar = JSON.parse(localStorage.getItem('user'));
    //console.log(userVar);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      userType: userVar.details.userType,
    };
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    let userActionsAddMenu;
    let userActionsListMenu;
    let userDropMenu;
    if (this.state.userType === 'dealeradmin') {
      userActionsAddMenu = <NavDropdown.Item href="/adduser">Add User</NavDropdown.Item>;
      userActionsListMenu = <NavDropdown.Item href="/listusers">User List</NavDropdown.Item>;
      userDropMenu = <Fragment>
                  <NavDropdown title="Users" nav inNavbar className="px-0">
                      <NavDropdown.Item href="/adduser">Add User</NavDropdown.Item>
                      <NavDropdown.Item href="/listusers">User List</NavDropdown.Item>
                  </NavDropdown></Fragment>
    } else {
      userActionsAddMenu = '';
      userActionsListMenu = '';
      userDropMenu = '';
    }

    let promotionsDropMenu = <Fragment>
                  <NavDropdown title="Rebate Codes" nav inNavbar className="px-0">
                      <NavDropdown.Item href="https://sunpowerdocsview.s3-us-west-2.amazonaws.com/SunPowerActiveRebateCodesList.pdf">Active Rebate Codes<FilePdfOutlined className="align-middle ps-2 pb-1" /></NavDropdown.Item>
                      <NavDropdown.Item href="/seasonal">Seasonal Promotions</NavDropdown.Item>
                  </NavDropdown></Fragment>
    

    let resourcesDropMenu = <Fragment>
                  <NavDropdown title="Resources" nav inNavbar className="px-0">
                      <NavDropdown.Item href="/guidesupport">Guides & Support</NavDropdown.Item>
                      <NavDropdown.Item href="/faqs">FAQs</NavDropdown.Item>
                      <NavDropdown.Item href="/consumersiteinfo">Consumer Site Information</NavDropdown.Item>
                      <NavDropdown.Item href="/contactus">Contact Us</NavDropdown.Item>
                  </NavDropdown></Fragment>

    let rebatesDropMenu = <Fragment>
                  <NavDropdown title="Rebates" nav inNavbar className="px-0">
                      <NavDropdown.Item href="/submittedrebates">Submitted Rebates</NavDropdown.Item>
                      <NavDropdown.Item href="/upload">Upload Rebates</NavDropdown.Item>
                  </NavDropdown></Fragment>
    const userVar = JSON.parse(localStorage.getItem('user'));

    return (
      
            <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm bg-white px-2 ">
              <Container fluid>
              <Navbar.Brand href="/"><img src="images/SunPower.png" width="140px" alt="logo" /></Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home
                  </Nav.Link>
                  {rebatesDropMenu}
                  {promotionsDropMenu}
                  <Nav.Link href="/uploaddocs">Upload Documents</Nav.Link>
                  <Nav.Link href="/reports">Reports</Nav.Link>
                  {userDropMenu}
                  {resourcesDropMenu}
                  <span className="divider ms-4 d-none d-lg-block" />
                  <Dropdown align="end" className="ms-lg-4 me-lg-5 btn-sm">
                  <Dropdown.Toggle align="end">
                    <UserOutlined className="align-middle pb-1" /> {userVar.details.fname} {userVar.details.lname}
                  </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header><span className="fw-bold">Name:</span> {userVar.details.fname} {userVar.details.lname}</Dropdown.Header>
                      <Dropdown.Header><span className="fw-bold">Dealer Name:</span> {userVar.details.name}</Dropdown.Header>
                      <Dropdown.Header><span className="fw-bold">Email:</span> {userVar.details.email}</Dropdown.Header>
                      <Dropdown.Header><span className="fw-bold">User ID:</span> {userVar.details.id}</Dropdown.Header>
                      <Dropdown.Divider className="mx-2" />
                      <Dropdown.Header><Button href="/logout" className="btn btn-orange-secondary">Log out</Button></Dropdown.Header>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
              </Container>
            </Navbar>
    );
  }
}