import React, {Fragment, useEffect, useState} from 'react';
import { Row, Col, Container, Card, Alert, Form} from 'react-bootstrap';
import 'antd/dist/antd.css';
import "react-datepicker/dist/react-datepicker.css";
import './Dashboard.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { Select } from 'antd';
//import { Form, Select } from 'antd';

const { Option } = Select;

  
const DashboardContent = () => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [spinnerText, setSpinnerText] = useState('Loading your content...');
    const [chartDataCount, setChartDataCount] = useState([{name: '', rcount: 0, ramount: 0}]);
    const [chartDataAmount, setChartDataAmount] = useState([{name: '', rcount: 0, ramount: 0}]);
    const [yrOptionsCount, setYrOptionsCount] = useState([]);
    const [yrOptionsAmt, setYrOptionsAmt] = useState([]);
    const [yrOptionsStatus, setYrOptionsStatus] = useState([]);
    const [rcount, setRCount] = useState([]);
    const [ramount, setRAmt] = useState([]);
    const [statusCount, setSCount] = useState([]);
    const [countYr, setCountYr] = useState(0);
    const [amtYr, setAmtYr] = useState(0);
    const [statusYr, setStatusYr] = useState(0);
    const [countMon, setCountMon] = useState('year');
    const [amtMon, setAmtMon] = useState('year');
    const [statusMon, setStatusMon] = useState('year');
    const [rebateCount, setRebateCount] = useState(0);
    const [rebateAmt, setRebateAmt] = useState(0);
    const [defaultCountYr, setDefaultCountYr] = useState('');
    const [defaultAmtYr, setDefaultAmtYr] = useState('');
    const [defaultStatsYr, setDefaultStatsYr] = useState('');

    const [totalRebates, setTotalRebates] = useState(0);
    const [totalProcessed, setTotalProcessed] = useState(0);
    const [totalRegistered, setTotalRegistered] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalApproved, setTotalApproved] = useState(0);
    const [totalDeclined, setTotalDeclined] = useState(0);
    const [statusCountYr, setStatusCountYr] = useState('');

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

    const getYTDData = (data, assocKey) => {
        let currentYr = new Date().getFullYear();
        const months = {1 : 'Jan', 2 : 'Feb', 3 : 'Mar', 4 : 'Apr', 5 : 'May', 6 : 'Jun', 7: 'Jul', 
            8 : 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};
        
        currentYr = (data[currentYr]) ? currentYr : currentYr - 1;
        if(assocKey == 'rcount') {
            setDefaultCountYr(currentYr);
        } else {
            setDefaultAmtYr(currentYr);
        }
        
        let mthCtr = 0; let chartData = []; let statusCount = 0; let rebateAmount = 0; let noOfYears = 1;
        
        //console.log(data[currentYr]);
        while(true && data[currentYr]) {
            let properties = Object.keys(data[currentYr]).reverse(); 
            properties.forEach(prop => {
                //console.log(`PropertyName: ${prop}, its Value: ${foo[prop]}`)
                if (mthCtr < 9) {
                    if (assocKey == 'rcount') {
                        chartData.push({'name': months[prop], 'rebatecount': data[currentYr][prop]});
                        //console.log(data[currentYr][prop]);
                        statusCount += parseInt(data[currentYr][prop]);
                        
                    } else {
                        chartData.push({'name': months[prop], 'rebateamount': parseFloat(data[currentYr][prop])});
                        rebateAmount += parseFloat(data[currentYr][prop]);
                    }
                }
                mthCtr++;
            }); 
            if (mthCtr > 8 || (noOfYears == 2)) {
                break;
            } else {
                currentYr--;
                noOfYears++;
            }
        }
        
        if (rebateCount == 0 && statusCount != 0) {
            setRebateCount(statusCount);
        }
        setRebateAmt(formatter.format(rebateAmount));
        let chartDataTmp = [];
        let keyProps = Object.keys(chartData).reverse(); 
        keyProps.forEach(prop => {
            chartDataTmp.push(chartData[prop]);
        }); 
        //console.log(chartDataTmp)
        //console.log(mthCtr);
        if (assocKey == 'rcount') {
            setChartDataCount(chartDataTmp);
        } else {
            setChartDataAmount(chartDataTmp);
        }
    }
    const userVar = JSON.parse(localStorage.getItem('user'));
    
    useEffect(() => {
        setSpinnerDisplay(true);
        
        axios.get(process.env.REACT_APP_BACKEND_URL+'/api/getDashboardData', {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
        .then(response => {
            setSpinnerDisplay(false);
            //console.log(response);
            let currentYr = new Date().getFullYear();
            getYTDData(response.data.rcount, 'rcount');
            getYTDData(response.data.ramount, 'ramount');
            //console.log(response.data.yrOptionsCount);
            let selectCount = []
            selectCount.push(currentYr);
            Object.keys(response.data.yrOptionsCount).map(index => {
                if (response.data.yrOptionsCount[index] != currentYr) {
                    selectCount.push(response.data.yrOptionsCount[index]);
                }
            })
            setYrOptionsCount(selectCount);
            let selectAmt = [];
            selectAmt.push(currentYr);
            Object.keys(response.data.yrOptionsAmt).map(index => {
                if (response.data.yrOptionsAmt[index] != currentYr) {
                    selectAmt.push(response.data.yrOptionsAmt[index]);
                }
            })
            setYrOptionsAmt(selectAmt);
            let selectStatus = [];
            selectStatus.push(currentYr);
            Object.keys(response.data.yrStatusCount).map(index => {
                if (response.data.yrStatusCount[index] != currentYr) {
                    selectStatus.push(response.data.yrStatusCount[index]);
                }
            })
            setYrOptionsStatus(selectStatus);
            
            setRCount(response.data.rcount);
            setRAmt(response.data.ramount);
            
            setSCount(response.data.statusCount);
            getYTDStatusData(response.data.statusCount, 'Rebate Registered');
            getYTDStatusData(response.data.statusCount, 'approved');
            getYTDStatusData(response.data.statusCount, 'declined');
            getYTDStatusData(response.data.statusCount, 'in process');
            getYTDStatusData(response.data.statusCount, 'processing');
            getYTDStatusData(response.data.statusCount, 'totalRebates');
            
            
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error == "token_expired") {
                    localStorage.clear();
                    window.location.href = '/login';
                }
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
              }
        });
        
    }, []);

    const calculateRebateCount = (value) => {
        //console.log('value:',value);
        if (countYr != 0) {
            if (countMon == 'year') {
                let statusCount = 0;
                let properties = Object.keys(rcount[countYr]).reverse(); 
                properties.forEach(prop => {
                    statusCount += parseInt(rcount[countYr][prop]);
                }); 
                setRebateCount(statusCount);
            } else {
                if (value) {
                    //console.log('inside value:',rcount, countYr, value, rcount[countYr][value]);
                    setRebateCount(rcount[countYr][value]);
                } else {
                    setRebateCount(rcount[countYr][countMon]);
                }
            }
        }
    }

    const calculateRebateAmount = (value) => {
        //console.log('value:',value);
        if (amtYr != 0) {
            if (amtMon == 'year') {
                let statusCount = 0;
                let properties = Object.keys(ramount[amtYr]).reverse(); 
                properties.forEach(prop => {
                    statusCount += parseFloat(ramount[amtYr][prop]);
                }); 
                setRebateAmt(formatter.format(statusCount));
            } else {
                if (value) {
                    setRebateAmt(formatter.format(ramount[amtYr][value]));
                } else {
                    setRebateAmt(formatter.format(ramount[amtYr][amtMon]));
                }
                
                
            }
        }
    }

    const handleCountYr = (value) => {
        //console.log(value);
        setCountYr(value);
        const months = {1 : 'Jan', 2 : 'Feb', 3 : 'Mar', 4 : 'Apr', 5 : 'May', 6 : 'Jun', 7: 'Jul', 
            8 : 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};
        const yrVals = rcount[value];
        let chartDataTmp = [];
        if (yrVals) {
            let keyProps = Object.keys(yrVals);
            
            keyProps.forEach(prop => {
                chartDataTmp.push({'name': months[prop], 'rebatecount': yrVals[prop]});
            });
        } 
        
        setChartDataCount(chartDataTmp);
        calculateRebateCount(undefined);
    }

    const handleCountMon = (value) => {
        setCountMon(value);
        calculateRebateCount(value);
    }

    const handleAmtYr = (value) => {
        setAmtYr(value);
        const months = {1 : 'Jan', 2 : 'Feb', 3 : 'Mar', 4 : 'Apr', 5 : 'May', 6 : 'Jun', 7: 'Jul', 
            8 : 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};
        const yrVals = ramount[value];
        let chartDataTmp = [];
        if (yrVals) {
            let keyProps = Object.keys(yrVals);
        
            keyProps.forEach(prop => {
                chartDataTmp.push({'name': months[prop], 'rebateamount': parseFloat(yrVals[prop])});
            }); 
        }
        
        //console.log(chartDataTmp);
        setChartDataAmount(chartDataTmp);
        calculateRebateAmount(undefined);
    }

    const handleAmountMon = (value) => {
        setAmtMon(value);
        calculateRebateAmount(value);
    }

    const handleStatusYr = (value) => {
        setStatusYr(value);
        calculateStatusCountValues(value, 'year', 'Rebate Registered');
        calculateStatusCountValues(value, 'year', 'approved');
        calculateStatusCountValues(value, 'year', 'declined');
        calculateStatusCountValues(value, 'year', 'in process');
        calculateStatusCountValues(value, 'year', 'processing');
        calculateStatusCountValues(value, 'year', 'totalRebates');
    }

    const handleStatusMon = (value) => {
        setStatusMon(value);
        calculateStatusCountValues(value, 'month', 'Rebate Registered');
        calculateStatusCountValues(value, 'month', 'approved');
        calculateStatusCountValues(value, 'month', 'declined');
        calculateStatusCountValues(value, 'month', 'in process');
        calculateStatusCountValues(value, 'month', 'processing');
        calculateStatusCountValues(value, 'month', 'totalRebates');
    }

    const getYTDStatusData = (data, status) => {
        let currentYr = new Date().getFullYear();
        const months = {1 : 'Jan', 2 : 'Feb', 3 : 'Mar', 4 : 'Apr', 5 : 'May', 6 : 'Jun', 7: 'Jul', 
            8 : 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};
        //console.log(data);
        currentYr = (data[status][currentYr]) ? currentYr : currentYr - 1;
        if (statusCountYr == 0) {
            setStatusCountYr(currentYr)
        }
        currentYr = (statusCountYr != 0) ? statusCountYr : currentYr;
        let properties = Object.keys(data[status][currentYr]).reverse(); 
        let totalCtr = 0;
        //console.log(currentYr);
        properties.forEach(prop => {
            totalCtr += parseInt(data[status][currentYr][prop]);
        }); 
        
        if (status == 'Rebate Registered') {
            setTotalRegistered(totalCtr);
        } else if (status == 'approved') {
            setTotalApproved(totalCtr);
        } else if (status == 'declined') {
            setTotalDeclined(totalCtr);
        } else if (status == 'in process') {
            setTotalPending(totalCtr);
        } else if (status == 'totalRebates') {
            setTotalRebates(totalCtr);
        } else {
            setTotalProcessed(totalCtr);
        }
    }

    const calculateStatusCountValues = (value, type, status) => {
        let yrSelect = statusYr; let monSelect = statusMon;
        if (type == 'year') {
            yrSelect = value
        } else {
            monSelect = value;
        }
        if (monSelect == 'year') {
            let statsCount = 0;
            if (statusCount[status] && statusCount[status][yrSelect]) {
                let properties = Object.keys(statusCount[status][yrSelect]).reverse(); 
                properties.forEach(prop => {
                    statsCount += parseInt(statusCount[status][yrSelect][prop]);
                }); 
            }
            
            if (status == 'Rebate Registered') {
                setTotalRegistered(statsCount);
            } else if (status == 'approved') {
                setTotalApproved(statsCount);
            } else if (status == 'declined') {
                setTotalDeclined(statsCount);
            } else if (status == 'in process') {
                setTotalPending(statsCount);
            } else if (status == 'totalRebates') {
                setTotalRebates(statsCount);
            } else {
                setTotalProcessed(statsCount);
            }
        } else {
            const statusDisplayCOunt = (statusCount[status][yrSelect] && statusCount[status][yrSelect][monSelect]) ? statusCount[status][yrSelect][monSelect] : 0;
            if (status == 'Rebate Registered') {
                setTotalRegistered(statusDisplayCOunt);
            } else if (status == 'approved') {
                setTotalApproved(statusDisplayCOunt);
            } else if (status == 'declined') {
                setTotalDeclined(statusDisplayCOunt);
            } else if (status == 'in process') {
                setTotalPending(statusDisplayCOunt);
            } else if (status == 'totalRebates') {
                setTotalRebates(statusDisplayCOunt);
            } else {
                setTotalProcessed(statusDisplayCOunt);
            }
            
        }
    }
    
    return (
        <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text={spinnerText}
                >
        <Container className="pb-4 vh-fill">
            <Row className="pt-md-5">
                <Col className="pt-5">
                    <h4>Hello, {userVar.details.fname}</h4>
                    <h6 className="pb-2">Welcome to the SunPower Rebate Portal</h6>
                    <Alert className="alert alert-light bg-sp-blue text-white shadow-sm pt-3 px-4">
                        <h6 className="text-white d-inline">Update your closed sales:</h6>
                        <p className="mb-0 d-inline fw-light"> Please visit the <a href="https://sunpower1.force.com/signin" target="_blank" className="blue-link text-white">SunPower Portal</a> to report your closed sales.</p>
                    </Alert>              
                </Col>
            </Row>
            <Row className="g-2">
                <Col lg={4} md={12}>
                    <Card className="p-4 h-100">
                        <Row className="border-bottom pb-2">
                            <Col lg={9} md={7} sm={8} className="align-self-center">
                                <h6>Total Rebates Approved/Processed</h6>
                            </Col>
                            <Col lg={3} md={5} sm={4}>
                                <h2 className="sp-green fs-5 fw-bold text-sm-end">{rebateCount}</h2>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col xs={6}>
                            <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                <Select
                                    onChange={handleCountYr}
                                    defaultValue={defaultCountYr}
                                    style={{ width: 160 }}
                                > 
                                {yrOptionsCount.map(yrVal =>
                                (<Option value={yrVal}>{yrVal}</Option>))}
                                </Select>
                                
                            </Col>
                            <Col xs={6}>
                            <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                        <Select defaultValue="year" className="w-100" onChange={handleCountMon}>
                                            <Option value="year">Entire Year</Option>
                                            <Option value="1">January</Option>
                                            <Option value="2">February</Option>
                                            <Option value="3">March</Option>
                                            <Option value="4">April</Option>
                                            <Option value="5">May</Option>
                                            <Option value="6">June</Option>
                                            <Option value="7">July</Option>
                                            <Option value="8">August</Option>
                                            <Option value="9">September</Option>
                                            <Option value="10">October</Option>
                                            <Option value="11">November</Option>
                                            <Option value="12">December</Option>
                                        </Select>
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <Col>
                            <div style={{ width: '100%', height: 240 }}>
                            <ResponsiveContainer>
                            <LineChart data={chartDataCount} margin={{ top: 5, right: 5, bottom: 5, left: -30 }}>
                                <Line type="monotone" dataKey="rebatecount" stroke="#F79320" />
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="name" stroke="#808080" />
                                <YAxis padding={{ top: 20, bottom: 20 }} stroke="#808080" />
                                <Tooltip />
                            </LineChart>
                            </ResponsiveContainer>
                            </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={4} md={12}>
                    <Card className="p-4 h-100">
                        <Row className="border-bottom pb-2">
                            <Col md={7} sm={8} className="align-self-center">
                                <h6>Total $ Amount of Rebates</h6>
                            </Col>
                            <Col md={5} sm={4}>
                                <h2 className="sp-green fs-5 fw-bold text-sm-end">{rebateAmt}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={6}>
                            <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                <Select
                                    onChange={handleAmtYr}
                                    defaultValue={defaultAmtYr}
                                    style={{ width: 160 }}
                                > 
                                {yrOptionsAmt.map(yrVal =>
                                (<Option value={yrVal}>{yrVal}</Option>))}
                                </Select>
                            </Col>
                            <Col md={6} xs={6}>
                            <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                        <Select defaultValue="year" className="w-100" onChange={handleAmountMon}>
                                            <Option value="year">Entire Year</Option>
                                            <Option value="1">January</Option>
                                            <Option value="2">February</Option>
                                            <Option value="3">March</Option>
                                            <Option value="4">April</Option>
                                            <Option value="5">May</Option>
                                            <Option value="6">June</Option>
                                            <Option value="7">July</Option>
                                            <Option value="8">August</Option>
                                            <Option value="9">September</Option>
                                            <Option value="10">October</Option>
                                            <Option value="11">November</Option>
                                            <Option value="12">December</Option>
                                        </Select>
                            </Col>
                            
                        </Row>
                        <Row className="pt-4">
                            <Col>
                                <div style={{ width: '100%', height: 250 }}>
                                    <ResponsiveContainer>
                                    <LineChart data={chartDataAmount} margin={{ top: 5, right: 2, bottom: 5, left: -2 }}>
                                        <Line type="monotone" dataKey="rebateamount" stroke="#F79320" />
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="name" stroke="#808080" />
                                        <YAxis padding={{ top: 20, bottom: 20 }} stroke="#808080" />
                                        <Tooltip />
                                    </LineChart>
                                    
                                    
                                    </ResponsiveContainer>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={4} md={12}>
                    <Card className="p-4 h-100">
                        <Row className="border-bottom pb-2">
                            <Col sm={5} className="align-self-center">
                                <h6>Total Rebates</h6>
                            </Col>
                            <Col sm={7}>
                                <h2 className="sp-green fs-5 fw-bold text-sm-end">{totalRebates}</h2>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col md={6} xs={6}>
                            <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                <Select
                                    onChange={handleStatusYr}
                                    defaultValue={statusCountYr}
                                    style={{ width: 160 }}
                                > 
                                {yrOptionsStatus.map(yrVal =>
                                
                                (<Option value={yrVal}>{yrVal}</Option>))}
                                </Select>
                            </Col>
                            <Col md={6} xs={6}>
                            <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                        <Select defaultValue="year" className="w-100" onChange={handleStatusMon}>
                                            <Option value="year">Entire Year</Option>
                                            <Option value="1">January</Option>
                                            <Option value="2">February</Option>
                                            <Option value="3">March</Option>
                                            <Option value="4">April</Option>
                                            <Option value="5">May</Option>
                                            <Option value="6">June</Option>
                                            <Option value="7">July</Option>
                                            <Option value="8">August</Option>
                                            <Option value="9">September</Option>
                                            <Option value="10">October</Option>
                                            <Option value="11">November</Option>
                                            <Option value="12">December</Option>
                                        </Select>
                            </Col>
                        </Row>
                        <Row className="pt-4 text-center px-2 g-2">
                            <Col xs={12} className="bg-light rounded pt-2">
                                <p className="total-rebates-label mb-2">Processed</p>
                                <h2 className="sp-green fs-4 fw-bold">{totalProcessed}</h2>
                            </Col>
                            <Col xs={6} className="bg-light rounded pt-2">
                                <p className="total-rebates-label mb-2">Registered</p>
                                <h2 className="sp-green fs-4 fw-bold">{totalRegistered}</h2>
                            </Col>
                            <Col xs={6} className="bg-light rounded pt-2">
                                <p className="total-rebates-label mb-2">Pending</p>
                                <h2 className="sp-green fs-4 fw-bold">{totalPending}</h2>
                            </Col>
                            <Col xs={6} className="bg-light rounded pt-2">
                                <p className="total-rebates-label mb-2">Approved</p>
                                <h2 className="sp-green fs-4 fw-bold">{totalApproved}</h2>
                            </Col>
                            <Col xs={6} className="bg-light rounded pt-2">
                                <p className="total-rebates-label mb-2">Declined</p>
                                <h2 className="sp-green fs-4 fw-bold">{totalDeclined}</h2>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
        </LoadingOverlay>
    );
}
export default DashboardContent;
