import React, { Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import { Container, Row, Col } from 'reactstrap';
import './SideCard.css';

export default class SideCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                dealerFname: '',
                dealerLname: '',
                email: '',
                id: '',
                name: ''
            },
            submitted: false
        };
    }

    componentDidMount() {
        let userData= this.state.user;
        const storedData = JSON.parse(localStorage.getItem('user'));
        console.log(storedData.details);
        this.setState({user: storedData.details});
    }

    render() {
        return(
            <Fragment>
                <Container fluid className="pt-5">
                    <Row className="pt-4">
                        <Col lg={2} className="pt-4">
                            <table className="table table-borderless table-sm DealerInfo bg-light">
                                <tbody>
                                <tr>
                                    <td>{this.state.user.name}</td>
                                </tr>
                                <tr>
                                    <td>{this.state.user.fname} {this.state.user.lname}</td>
                                    </tr>
                                    <tr>
                                    <td>{this.state.user.email}</td>
                                    </tr>
                                    <tr>
                                    <td>{this.state.user.id}</td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );

    };
}
/*const SideCard = () => (
  <Fragment>
      <br />
      <Container>
          <Row className="solarcard-dark">
              <Col>
                  <h4 className="text-center text-white font-weight-bolder">Welcome Sushant!</h4>
              </Col>
          </Row>
          <Row className="solarcard-dark">
              <Col>

                      <table className="table table-borderless table-sm rounded DealerInfo text-white">
                          <tbody>
                          <tr>
                              <td align='center' colspan="2"><strong>Dealer Information</strong></td>

                          </tr>
                          <tr>
                              <td align='right' width="50%">First Name</td>
                              <td>Sushant</td>
                          </tr>
                          <tr>
                              <td align='right'>Last Name</td>
                              <td>Vengurlekar</td>
                          </tr>
                          <tr>
                              <td align='right'>Email</td>
                              <td>usctrojan77@gmail.com</td>
                          </tr>
                          <tr>
                              <td align='right'>User ID</td>
                              <td>1</td>
                          </tr>
                          </tbody>
                      </table>
              </Col>



          </Row>
          <br />
      </Container>
  </Fragment>
);

export default SideCard;*/
