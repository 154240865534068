import React, {Fragment} from 'react';
import SideCard from './SideCard';
import Topper from './Topper';
import ConsumerSiteInfoContent from './ConsumerSiteInfoContent';
import { Footer } from '../../footer/Footer';


const ConsumerSiteInfo = () => {
    return (
        <Fragment>
            <Topper />
            <ConsumerSiteInfoContent />
            <Footer></Footer>
        </Fragment>
    );
}
export default ConsumerSiteInfo;