import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import queryString from 'query-string'
import './RegisterPage.css';
import axios from 'axios';
import { Footer } from '../footer/Footer';
import { PasswordStrengthMeter } from '../PasswordStrengthMeter';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                dealerFname: '',
                dealerLname: '',
                username: '',
                password: '',
                cpassword: '',
                email: '',
                dealerAddr: '',
                dealerAddr2: '',
                city: '',
                state: '',
                zip: '',
                autoapp: '',
                emapp: '',
                dealercode: '',
                userType: ''
            },
            submitted: false,
            usernameError: false,
            autoapprove: false,
            emailapprove: false,
            pwdWeak: false,
            pwdScore: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pwdScore = this.pwdScore.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this._mounted = true
        if (values.dealer === undefined) {
            values.dealer = '1111111';
            window.location.href='/login';
        }
        axios.get(process.env.REACT_APP_BACKEND_URL+'/dealerlookup.php?dealercode='+values.dealer)
            .then(response => {
                //console.log(response.data);
                //console.log(response.data.length);
                if (response.data.length !== undefined && response.data.length === 0) {
                    window.location.href='/login';
                }
                this.setState({user:
                        {
                            dealerAddr: response.data.address1,
                            dealerAddr2: response.data.address2,
                            city: response.data.city,
                            state: response.data.state,
                            zip: response.data.zip,
                            userType: response.data.userType
                        }});

            });
        this.setState({user: {dealercode: values.dealer}});
    }


    handleChange(event) {
        let { name, value } = event.target;
        if (name == 'autoapp' || name == 'emapp') {
            value = true;
        }
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
        if (name == 'autoapp') {
            this.setState({autoapprove: true, emailapprove: false});
        } else if (name == 'emapp') {
            this.setState({autoapprove: false, emailapprove: true});
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const values = queryString.parse(this.props.location.search);
        this._mounted = true
        if (values.dealer === undefined) {
            values.dealer = '1111111';
            window.location.href='/login';
        }
        const { user } = this.state;
        const postBody = {dealercode: values.dealer, user: user};
        //console.log(user);
        //console.log(values);
        //console.log(this.state.pwdScore);
        if (user.dealerFname && user.dealerLname && user.username && user.password && user.email && user.dealerAddr  && user.city && user.zip && ((user.userType == 'dealeradmin' && (user.autoapp || user.emapp)) || user.userType == 'dealeruser') && this.state.pwdScore && this.state.pwdScore >= 3) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/registerdealer.php`, { postBody })
                .then(res => {
                    console.log(res);
                    if (!res.data.username) {
                        this.setState({ usernameError: true });
                    } else {
                        this.setState({ usernameError: false });
                        this.props.register(user);
                    }
                    
                    //console.log(user);
                });
        } else if (this.state.pwdScore < 3) {
            this.setState({pwdWeak: true});
        }
    }

    pwdScore(pwdScore) {
        //console.log(pwdScore);
        this.setState({pwdScore: pwdScore});
    }

    render() {
        const { registering  } = this.props;
        const { user, submitted } = this.state;
        console.log(user);
        console.log(submitted);
        const { password } = this.state.user;
        return (
            <Fragment>
                <div className="solar">
                    <br/><br/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-sm-12">
                                <div className="card rounded shadow-lg solarcard">
                                { this.state.usernameError ? <div className="alert alert-danger">Username is already in use.</div> : null}
                                { this.state.pwdWeak ? <div className="alert alert-danger">Password used is weak.</div> : null}
                                    <div className="card-header">
                                        <br />

                                        <h1 className="font-weight-bold">CREATE AN ACCOUNT</h1>
                                        <p>Let's set up your Dealer Admin Rebate Account!</p>

                                    </div>


                                    <div className="card-body">
                                        <form name="form" onSubmit={this.handleSubmit}>
                                            <div className={'form-group' + (submitted && !user.username ? ' has-error' : '')}>
                                                <label htmlFor="username" className="sr-only">Create Username</label>
                                                <input type="text" className="form-control" name="username" value={user.username} onChange={this.handleChange} />
                                                {submitted && !user.username &&
                                                <div className="help-block">Username is required</div>
                                                }
                                            </div>
                                            <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                                                <label htmlFor="password" className="sr-only">Create Password</label>
                                                <input autoComplete="off" type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                                                {submitted && !user.password &&
                                                <div className="help-block">Password is required</div>
                                                }
                                                {user.password &&
                                                    <PasswordStrengthMeter password={password} onPwdCheck={this.pwdScore} />
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.cpassword ? ' has-error' : '')}>
                                                <label htmlFor="cpassword" className="sr-only">Confirm Password</label>
                                                <input type="password" className="form-control" name="cpassword" value={user.cpassword} onChange={this.handleChange} />
                                                {submitted && !user.cpassword &&
                                                <div className="help-block">Confirm Password is required</div>
                                                }
                                                {submitted && user.cpassword != user.password &&
                                                <div className="help-block">Confirm Password should match the password</div>
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.email ? ' has-error' : '')}>
                                                <label htmlFor="email" className="sr-only">Email</label>
                                                <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange} />
                                                {submitted && !user.email &&
                                                <div className="help-block">Email is required</div>
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.dealerFname ? ' has-error' : '')}>
                                                <label htmlFor="dealerFname" className="sr-only">Dealer Contact First Name</label>
                                                <input type="text" className="form-control" name="dealerFname" value={user.dealerFname} onChange={this.handleChange} />
                                                {submitted && !user.dealerFname &&
                                                <div className="help-block">Dealer Contact First Name is required</div>
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.dealerLname ? ' has-error' : '')}>
                                                <label htmlFor="dealerLname" className="sr-only">Dealer Contact Last Name</label>
                                                <input type="text" className="form-control" name="dealerLname" value={user.dealerLname} onChange={this.handleChange} />
                                                {submitted && !user.dealerLname &&
                                                <div className="help-block">Dealer Contact Last Name is required</div>
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.dealerAddr ? ' has-error' : '')}>
                                                <label htmlFor="dealerAddr" className="sr-only">Dealer Address 1</label>
                                                <input type="text" className="form-control" name="dealerAddr" value={user.dealerAddr} onChange={this.handleChange} />
                                                {submitted && !user.dealerAddr &&
                                                <div className="help-block">Dealer Address 1 is required</div>
                                                }
                                            </div>

                                            <div className={'form-group' + (submitted && !user.dealerAddr2 ? ' has-error' : '')}>
                                                <label htmlFor="dealerAddr2" className="sr-only">Dealer Address 2</label>
                                                <input type="text" className="form-control" name="dealerAddr2" value={user.dealerAddr2} onChange={this.handleChange} />
                                                
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className={'form-group' + (submitted && !user.city ? ' has-error' : '')}>
                                                        <label htmlFor="city" className="sr-only">City</label>
                                                        <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} />
                                                        {submitted && !user.city &&
                                                        <div className="help-block">City is required</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className={'form-group' + (submitted && !user.state ? ' has-error' : '')}>
                                                        <label htmlFor="state" className="sr-only">State</label>
                                                        <input type="text" className="form-control" name="state" value={user.state} onChange={this.handleChange} />
                                                        {submitted && !user.state &&
                                                        <div className="help-block">State is required</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className={'form-group' + (submitted && !user.zip ? ' has-error' : '')}>
                                                        <label htmlFor="zip" className="sr-only">Zip</label>
                                                        <input type="text" className="form-control" name="zip" value={user.zip} onChange={this.handleChange} />
                                                        {submitted && !user.zip &&
                                                        <div className="help-block">Zip is required</div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                                {user.userType == 'dealeradmin'  &&
                                                <p className="text-center">How would you like your customer's Rebates
                                                    submitted?</p>
                                                }
                                                {user.userType == 'dealeradmin' &&  !user.emapp && !user.autoapp &&
                                                <div className="help-block text-center">You have to select either of the approval options</div>
                                                }
                                                {user.userType == 'dealeradmin' &&
                                                <div className="row">
                                                
                                                    <div className="col">

                                                            <div className="custom-control custom-radio text-center">
                                                                <input type="radio" className="custom-control-input"
                                                                    id="autoapp" name="autoapp" value={user.autoapp} checked={this.state.autoapprove} onChange={this.handleChange} />
                                                                    <label className="custom-control-label"
                                                                        htmlFor="autoapp">Auto Approve</label>
                                                            </div>

                                                    </div>

                                                    <div className="col">

                                                            <div className="custom-control custom-radio text-center">
                                                                <input type="radio" className="custom-control-input"
                                                                    id="emapp" name="emapp" value={user.emapp} checked={this.state.emailapprove} onChange={this.handleChange} />
                                                                    <label className="custom-control-label" htmlFor="emapp">Approve
                                                                        From Email</label>
                                                            </div>

                                                    </div>
                                                </div>
                                                }

{                                               user.userType == 'dealeradmin' && !user.emapp && !user.autoapp &&
                                                <br />
                                                }
                                            {user.userType == 'dealeradmin'  &&
                                            <p className="text-center small">Dealers must approve or disapprove rebates
                                                submitted within 5 business days. If Dealer does not process Rebate
                                                within 5 business days the system will auto approve.</p>
                                            }
                                            <br />
                                            
                                            <div className="form-group">
                                                <p className="text-center">
                                                    <button className="btn btn-lg btn-blue btn-primary">CREATE ACCOUNT</button></p>
                                                {registering &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }

                                            </div>

                                            <br />
                                        </form>
                                    </div>
                                    <div className="card-footer text-center">
                                        <p>Already have an account? <Link to="/login" className="text-dark">Login here</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </Fragment>
        );
    }
}

function mapState(state) {
    const { registering } = state.registration;
    return { registering };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };