import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import Topper from "./Topper";
import '../HomePage.css';
import GeneralReport from './GeneralReport';
import { Footer } from '../../footer/Footer';

class Reports extends Component {
    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }

    render() {
        const { user, users } = this.props;
        return (

            <Fragment>
                    <Topper />
                    <GeneralReport/>
                <Footer></Footer>
            </Fragment>

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHomePage = connect(mapState, actionCreators)(Reports);
export { connectedHomePage as Reports };