import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./ContactUs.css";
import { Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Container, Row, Col, Form } from 'react-bootstrap';


class ContactUs extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          success : false,
          contactType: 'Submission',
          your_name: '',
          your_email: '',
          message: '',
          formData : '',
          templateMessage: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureInput = this.captureInput.bind(this);
        this.contactUs = this.contactUs.bind(this);
        
      }
    
      toggle(e) {
          console.log(e);
          //console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({contactType: e});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

      captureInput = e => {
        //console.log(e.target.name);
        //console.log(e.target.value);
        //this.setState({[e.target.name] : e.target.value});
        //console.log(e);
        let obj = {};
        obj[e.target.name] = e.target.value
        this.setState(obj);
        //console.log(this.state);
    };

    contactUs(e) {
        e.preventDefault();
        /*const body = new FormData(this.form);
        console.log(body);
        let tmp = this.state.formData1
        console.log(tmp);
        const formData  = new FormData(this.form);
        formData.append("subject", this.state.contactType);
        console.log(this.state.contactType)
        formData.append("fullName", this.state.your_name);
        console.log(this.state.your_name)
        formData.append("email", this.state.your_email);
        console.log(this.state.your_email)
        formData.append("message", this.state.message);
        console.log(this.state.message)
        const userVar = JSON.stringify(localStorage.getItem('user'));
        formData.append("userVar", userVar);
        this.setState({success: false, url : "", formData: formData});
        console.log(this.state);
        console.log(formData);*/
        
        if (this.state.contactType.length && this.state.your_name.length && this.state.your_email.length && this.state.message.length) {
            this.setState({spinner: true});
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/dealercontactus.php`,{subject: this.state.contactType, fullName: this.state.your_name, email: this.state.your_email, message: this.state.message})
            .then(response => {
                //console.log(response);
                const responseStatus = (response.data == 'success') ? true : false;
                //const tempValue = (responseStatus) ? responseStatus : response.data.error;
                const tempValue = (!responseStatus) ? response.data.error : false;
                this.setState({
                    success: responseStatus, 
                    templateMessage: tempValue, 
                    spinner: false,
                    contactType: 'Submission',
                    your_name: '',
                    your_email: '',
                    message: '',
                });
            })
            .catch(error => {
            alert(JSON.stringify(error));
            })
        } else {
            this.setState({
                templateMessage: 'All fields are mandatory and must be filled to submit your query', 
            });
        }
        
        /*axios({
            method: 'post',
            url: '${process.env.REACT_APP_BACKEND_URL}/dealercontactus.php',
            data: this.state.formData,
            headers: {
                'content-type': `multipart/form-data; boundary=${this.state.formData._boundary}`,
                },
            })
            .then(function (response) {
                //handle success
                console.log(response);
                
                const responseStatus = (response.data == 'success') ? true : false;
                this.setState({success: responseStatus, templateMessage: response.data.error, spinner: false});
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });*/
            
    }

      render() {
        const Success_message = () => (
            <div className="pt-5">
                <div className="alert alert-success">Your request has been successfully recorded and some one will respond back shortly.</div>
                <br/>
            </div>
        )
        const Error_message = () => (
            <div className="pt-5">
                <div className="alert alert-danger">{this.state.templateMessage}</div>
            </div>
        )

        
const { Option } = Select;

        return (
            <Fragment>
                <Container className="pt-5 vh-fill">
                    <Row className="py-md-5">
                        <Col className="pt-5">
                            {this.state.spinner ? <FontAwesomeIcon icon={faSpinner} transform="grow-7 left-1.5 up-2.2" spin /> : null}
                            {this.state.success ? <Success_message/> : null}
                            {this.state.templateMessage ? <Error_message/> : null}
                            <h3>Contact Us</h3>
                        </Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col lg={7}>
                            <h5>E-Customer Service</h5>
                            <Form ref={el => (this.form = el)} onSubmit={this.contactUs}>
                                <Form.Group className="py-2">
                                    <Form.Label htmlFor="subject" className="d-block">Subject:</Form.Label>
                                    <Select className="w-100" name="subject" id="subject" onChange={this.toggle}>
                                        <Option value="Dealer Inquiry Rebate Submission">Dealer Inquiry Rebate Submission</Option>
                                        <Option value="Dealer Inquiry Customer Data">Dealer Inquiry Customer Data</Option>
                                        <Option value="Dealer Inquiry Rebate Status">Dealer Inquiry Rebate Status</Option>
                                    </Select>
                                </Form.Group>
                                <Form.Group className="py-2">
                                        <Form.Label htmlFor="your_name">Your Name:</Form.Label>
                                        <Form.Control type='text' onChange={this.captureInput} id="your_name" name="your_name" value={this.state.your_name} />
                                </Form.Group>
                                <Form.Group className="py-2">
                                        <Form.Label htmlFor="your_email">Your Email:</Form.Label>
                                        <Form.Control type='email' onChange={this.captureInput} id="your_email" name="your_email" value={this.state.your_email} />
                                </Form.Group>
                                <Form.Group className="py-2">
                                    <Form.Label htmlFor="message">Message:</Form.Label>
                                    <Form.Control as="textarea" style={{ height: '100px' }} name="message" id="message" onChange={this.captureInput} value={this.state.message} />
                                </Form.Group>                                                     
                                <Button type="submit" className="btn-orange my-4">
                                    Contact Us
                                </Button>
                            </Form>
                        </Col>
                        <Col lg={4}>
                            <h5>Contact by Phone</h5>
                            <p>800-409-8960</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default ContactUs;