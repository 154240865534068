import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './ForgotPage.css';
import { Footer } from '../footer/Footer';
import axios from 'axios';
import { CalendarOutlined } from '@ant-design/icons';

class ForgotPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            submitted: false,
            loginError: false,
            registerError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        /*const values = queryString.parse(this.props.location.search);
        if (values.error !== undefined && values.error == 1) {
            console.log('alert');
            this.setState({ loginError: true });
        }*/
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const username  = this.state.username;
        const postBody = {username: this.state.username}
        //console.log(username);
        if (username) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/resetpassword.php`, { postBody })
                .then(res => {
                    console.log(res.data);
                    if(res.data.notFound) {
                        this.setState({ submitted: false, loginError: true, registerError: false });
                    } else if(!res.data.notFound && !res.data.register) {
                        this.setState({ submitted: false, loginError: false, registerError: true });
                    } else {
                        this.setState({ submitted: true, loginError: false, registerError: false });
                    }
                });
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username,  submitted } = this.state;
        return (
            <Fragment>
                <Container>
                    <Row className="vh-fill align-items-center justify-content-center">
                        <Col lg={6}>
                                <Card className="rounded shadow-lg border-0 p-4">
                                { this.state.loginError ? <div className="alert alert-danger">Username/Email is invalid</div> : null}
                                { this.state.registerError ? <div className="alert alert-danger">This email/username is not associated with any accounts that we have enrolled in our system. Please enroll in your admin account.</div> : null}
                                { this.state.submitted ? <div className="alert alert-success">A temporary password has been sent to your registered email. <br />Return to the <a href="/login">Login</a> page to reset your password.</div> : null}
                                <Card.Header className="bg-white border-0">
                                    <h1 className="text-center font-weight-bold">FORGOT PASSWORD</h1>
                                </Card.Header>
                                <Card.Body>
                                    <Form name="form" onSubmit={this.handleSubmit}>
                                        <Form.Group className={'py-2' + (submitted && !username ? ' has-error' : '')}>
                                            <Form.Label htmlFor="email">Username/Email:</Form.Label>
                                            <Form.Control input type="text" name="username" value={username} onChange={this.handleChange} />
                                            {submitted && !username &&
                                            <div className="help-block">Username/email is required</div>
                                            }
                                        </Form.Group>
                                        <div className="text-center pt-5">
                                            <Button className="btn btn-orange" onClick={this.handleSubmit}>Reset Password</Button>
                                            {loggingIn &&
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </Form>
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                <Footer></Footer>
            </Fragment>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(ForgotPage);
export { connectedLoginPage as ForgotPage };